import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineLink
} from "react-icons/ai"; 

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>



<p className="home-about-body">
I am Pushpendra Singh founder of SmartViewAi.com. Currently i am focusing on decentralized finance awareness and blockchain application and their respective markets. 
I came from the world of IT, i am familiar with the importance of blockchain technology/Defi/NFT/AI.
 I have 10 years of software development experience in industries ranging from supply chain management, centralized finance system and cryptocurrency market.  
 

  

              <br /> 
              
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row> 
        <Row> 
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://telegram.me/PushpendraKum"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineLink />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/PushpendraKum"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li> 
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/PushpendraSinghOfficial"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.youtube.com/@PUSHPENDRASINGHDIGITAL"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillYoutube />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
