import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import { ImBlog, ImBook, ImLink } from "react-icons/im";
import {
  AiFillStar,
  AiOutlineHome,
  AiFillYoutube,
  AiOutlineUser,
  AiFillContacts,
  AiFillPhone,
} from "react-icons/ai";

import { CgFileDocument } from "react-icons/cg";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <h2>Pushpendra.info</h2>
          {/* <img src={logo} className="img-fluid logo" alt="brand" /> */}
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} /> About
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/socialmedia"
                onClick={() => updateExpanded(false)}
              >
                <AiFillYoutube
                  style={{ marginBottom: "2px" }}
                />{" "}
                Social Media
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/press"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> Press
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => updateExpanded(false)}
              >
                <AiFillPhone  style={{ marginBottom: "2px" }} /> Contact
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://www.amazon.in/MILLIONAIRE-Investing-SmartViewAI-Currency-Pushpendra/dp/935886396X/ref=sr_1_1?camp=3638&amp;creative=24630&amp;keywords=BE+A+CRYPTO+MARKET+MILLIONAIRE+Pushpendra&amp;linkCode=ur2&amp;linkId=a944ca4303ded1a743d220c43bd175d7&amp;qid=1688788747&amp;s=books&amp;sr=1-1&_encoding=UTF8&tag=pushpendr01a9-21&linkCode=ur2&linkId=1e969da3b17d373802b2a838ebd7060b&camp=3638&creative=24630"
                target="_blank"
                rel="noreferrer"
              >
                <ImBook style={{ marginBottom: "2px" }} /> My book
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://SmartViewAi.com"
                target="_blank"
                rel="noreferrer"
              >
                <ImLink style={{ marginBottom: "2px" }} /> SmartViewAi.com
              </Nav.Link>
            </Nav.Item>

          
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
