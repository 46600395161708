import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SocialMediaCards from "./SocialMediaCards";
import Particle from "../Particle"; 
import chatify from "../../Assets/Projects/chatify.png";
import twitter from "../../Assets/Projects/twitter.png"; 

function SocialMedia() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our <strong className="purple">Social Media Channels </strong>
        </h1>
        <p style={{ color: "white" }}>
         Youtube
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={chatify}
              isBlog={false}
              title="Pushpendra Singh Digital - 308K+ Subscribers"
              description="This channel is realted crypto TA and updates"
              link="https://www.youtube.com/@PUSHPENDRASINGHDIGITAL"
              icon='youtube'
            />
          </Col>

          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={chatify}
              isBlog={false}
              title="SmartViewAi - 140K+ Subscribers"
              description="This channel is realted crypto TA and updates"
              link="https://www.youtube.com/@SmartViewAi"
              icon='youtube'
            />
          </Col>
          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={chatify}
              isBlog={false}
              title="Pushpendra Singh Shorts - 31K+ Subscribers"
              description="This channel is realted crypto TA and updates in shorts"
              link="https://www.youtube.com/@PushpendraSinghShorts"
              icon='youtube'
            />
          </Col> 
          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={chatify}
              isBlog={false}
              title="Push Vlog - 15+ Subscribers"
              description="This channel is for fun, i am recording here travel journey"
              link="https://www.youtube.com/@pushvlog1863"
              icon='youtube'
            />
          </Col>  
          </Row>
          <p style={{ color: "white" }}>
         Telegram
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={twitter}
              isBlog={false}
              title="PushpendraSinghOfficial" 
              link="https://telegram.me/PushpendraSinghOfficial"
                      icon='telegram'
            />
          </Col>
          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={twitter}
              isBlog={false}
              title="SmartViewAi" 
              link="https://telegram.me/SmartViewAi"
              icon='telegram'
            />
          </Col>
          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={twitter}
              isBlog={false}
              title="SmartViewHelp" 
              link="https://telegram.me/SmartViewHelp"
              icon='telegram'
            />
          </Col>
          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={twitter}
              isBlog={false}
              title="PushpendraKum" 
              link="https://telegram.me/PushpendraKum"
              icon='telegram'
            />
          </Col>
          
        </Row>


          <p style={{ color: "white" }}>
         Twitter
        </p>

          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={twitter}
              isBlog={false}
              title="Pushpendra Singh - 350K+ Twitter" 
              link="https://twitter.com/PushpendraKum"
                      icon='twitter'
            />
          </Col>
          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={twitter}
              isBlog={false}
              title="Pushpendra Tech - 112K+ Subscribers" 
              link="https://twitter.com/PushpendraTech"
              icon='twitter'
            />
          </Col>
          <Col md={3} className="project-card">
            <SocialMediaCards
              imgPath={twitter}
              isBlog={false}
              title="SmartViewAi - 67K+ Subscribers" 
              link="https://twitter.com/PushpendraTech"
              icon='twitter'
            />
          </Col>  
        </Row>
     
        <p style={{ color: "white" }}>
         Insta & FB Page
        </p>
<Row>
<Col md={6} className="project-card">
            <SocialMediaCards
              imgPath={twitter}
              isBlog={false}
              title="PushpendraSinghOfficial" 
              link="https://Instagram.com/PushpendraSinghOfficial"
              icon='insta'
            />
          </Col>
          <Col md={6} className="project-card">
            <SocialMediaCards
              imgPath={twitter}
              isBlog={false}
              title="PushpendraSinghOfficial" 
              link="https://fb.com/PushpendraSinghOfficial"
              icon='insta'
            />
          </Col>
</Row>

      </Container>
    </Container>
  );
}

export default SocialMedia;
