import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap"; 
function Contact() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section"> 
          <p>
            Contact us

    Email  <a href="mailto:Pksjobhub@gmail.com">Pksjobhub@gmail.com</a> <br/><br/> 
    Telegram <a href="https://telegram.me/PushpendraKum" > @PushpendraKum</a>  <br/><br/>

    For Premium <a href="https://telegram.me/SmartViewHelp" > @SmartViewHelp</a>  
          </p>
 
 
       </Container> 
       <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/> 
       <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>  <br/>   
    </div>
  );
}

export default Contact;
