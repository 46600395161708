import React from "react";
import Card from "react-bootstrap/Card"; 

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body> 
          <p style={{ textAlign: "justify" }}>
          I am Pushpendra Singh founder of SmartViewAi.com. Currently i am focusing on decentralized finance awareness and blockchain application and their respective markets. 
I came from the world of IT, i am familiar with the importance of blockchain technology/Defi/NFT/AI.
 I have 10 years of software development experience in industries ranging from supply chain management, centralized finance system and cryptocurrency market.  
 I enjoy making YouTube videos in crypto,blockchain,programming. 
          </p> 
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
