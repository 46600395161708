import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap"; 
function Press() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section"> 
          <p>
          under construction 
 </p>
       </Container>
    </div>
  );
}

export default Press;
